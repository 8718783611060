import React from 'react'
import Form from '../form/Form'
import './Hero.css'

function Hero() {
  return (
    <div className='hero-container'>
        <div className='hero-text-container'>
            <h1>Stock research <br className='mobile-break'/> made simple <br />for <span className='hero-span-every-investor'>every investor</span></h1>
      </div>
      <div className='hero-text-container'>
        <p>Analyze stocks in minutes <br className="mobile-break" />with our AI powered platform</p>
      </div>
      <div className='form-container'>
        <Form />
      </div>
      <div className='hero-image-container'>
        <img src="../../assets/data-terminalx4.png" alt="" />
      </div>
    </div>
  )
}

export default Hero
