import React from 'react'
import './Join.css'

function Join({ handleSubmit }) {
  return (
    <div>
      <button className='join-button' onClick={handleSubmit}>Join Waitlist</button>
    </div>
  )
}

export default Join
