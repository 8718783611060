import './App.css';
import { Navbar, Hero, Footer} from './components';

function App() {
  return (
    <>
    <div><Navbar /></div>
    <div><Hero /></div>
    <div><Footer /></div>
    </>
  );
}

export default App;
