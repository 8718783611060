import React from 'react'
import './Footer.css'

function Footer() {
  return (
    <div className='footer-container'>
      <h3>© 2024 Finalyst Inc. All rights reserved.</h3>
    </div>
  )
}

export default Footer
