import React from 'react';
import './Navbar.css';

function Navbar() {
  return (
    <div className='navbar-container'>
      <div className='navbar-logo'>
        <img src="../../logos/Finalyst-logo-250x56.png" alt="finalyst logo" />
      </div>
    </div>
  )
}

export default Navbar;
