import React, { useState } from 'react';
import '../../firebase/firebaseConfig';
import { getFirestore, collection, addDoc, query, where, getDocs } from 'firebase/firestore';
import Join from '../button/Join';
import './Form.css';

function Form() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  
  const db = getFirestore();

  const saveDataToFirestore = async () => {
    // Clear previous errors/success messages
    setError('');
    setSuccess('');

  try {
      // Query Firestore to check if the email already exists
      const q = query(collection(db, 'users'), where('email', '==', email));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // If the query returns results, the email already exists
        setError('This email is already registered.');
        return;
      }

      // If no existing document is found, add the new user
      const docRef = await addDoc(collection(db, 'users'), {
        name: name,
        email: email,
      });
      setSuccess("We're excited to have you on board.<br />You'll be first to know when we launch!");
      console.log('Document written with ID: ', docRef.id);
    } catch (e) {
      console.error('Error adding document: ', e);
      setError('An error occurred while adding your information.');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setEmail('');
    setName('');
    setSuccess(true);
};

  return (
    <div className="form-container">
      <form className="form-content" onSubmit={handleSubmit}>
        <div className="input-group">
          <input 
          type="text" 
          placeholder="First Name" 
          className="input-field" 
          value={name}
          onChange={(e) => setName(e.target.value)}
          /> 
          <input 
          type="email" 
          placeholder="Email address" 
          className="input-field" 
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        {error && <p className="error-message">{error}</p>}
        {success && <p className="success-message" dangerouslySetInnerHTML={{ __html: success }}></p>}
        <div className="join-button-container">
        <Join handleSubmit={saveDataToFirestore} />
      </div>
      </form>
    </div>
  );
}

export default Form;
